import React, { useState, useEffect} from 'react';
import { Link, NavLink} from 'react-router-dom';
import Logo from "../../../assets/images/logo.svg";
import DarkLogo from "../../../assets/images/logo-dark.svg";

const Header = () => {
	const [isActive, setActive] = useState(false);
	const toggleClass = () => {
		setActive(!isActive);
	};
	const [stickyClass, setStickyClass] = useState();
	useEffect(() => {
		window.addEventListener('scroll', stickNavbar);
		return () => {
			window.removeEventListener('scroll', stickNavbar);
		};
	}, []);
	const stickNavbar = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;
			windowHeight > 100 ? setStickyClass('fixed top-0 left-0 right-0 z-30') : setStickyClass('');
		}
	};
  return (
    <div className={`site__header absolute left-0 right-0 ${stickyClass}`}>
      <div className="theme__container">
        <div className={`site__header__inner pt-5 cmd:pt-8 flex justify-between ${isActive ? "active" : ""}`}>
          <Link to="/" className="site__logo">
            <img className="light_logo" src={Logo} alt="logo" />
			<img className="hidden dark_logo" src={DarkLogo} alt="logo" />
          </Link>
			<div className="navbar_wrapper ml-auto self-center">
				<div className={`hamburger-lines hidden ${isActive ? "active" : ""}`} onClick={toggleClass}>
					<span className="line line1"></span>
					<span className="line line2"></span>
					<span className="line line3"></span>
				</div>
			</div>
           	<nav>
				<ul className="flex">
					<li>
						<a href="#home" className="text-lg" onClick={toggleClass}>Home</a>
					</li>
					<li>
						<a href="#services" className="text-lg" onClick={toggleClass}>Services</a>
					</li>
					<li>
						<a href="#products" className="text-lg" onClick={toggleClass}>Products</a>
					</li>
					<li>
						<a href="#portfolio" className="text-lg" onClick={toggleClass}>Portfolio</a>
					</li>
					<li>
						<a href="#career" className="text-lg" onClick={toggleClass}>Career</a>
					</li>
					<li>
						<a href="#company" className="text-lg" onClick={toggleClass}>Company</a>
					</li>
					<li>
						<NavLink className="text-lg" to="#contact-us">Contact us</NavLink>
					</li>
				</ul>
			</nav>
        </div>  
      </div>
    </div>  
  )
}

export default Header