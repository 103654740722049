import React from 'react';
import { Route, Routes } from 'react-router-dom';

// __ __ Style Libaries/Components __ __ //
import TopBarProgress from 'react-topbar-progress-indicator';
import './assets/css/index.css';
import "./assets/css/theme.scss";
import "./assets/css/responsive.scss";

// __ __ Components/Helpers __ __ //
import Layout from './components/layout/layout.js';
const Home = React.lazy(() => import("./components/main/Home/Home.jsx"));

const App = () => {
	return (
		<React.Suspense fallback={<TopBarProgress />}>
			<Routes>
				{/* Guest routes here */}
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					{/* Add your open routes here <Route path="example" element={<Component />} /> */}
				</Route>
			</Routes>
		</React.Suspense>
	);
}

export default App;
